import React from "react";
import { type_page } from "./JMenuBar";
import { Button } from "@mui/material";

interface IB {
  pushLoadPage: (val: type_page) => void;
}

export default function BottomPage({ pushLoadPage }: IB) {
  return (
    <div>
      <Button onClick={() => pushLoadPage("contact")}>Hafa samband</Button>
      <Button onClick={() => pushLoadPage("team")}>Um okkur</Button>
      <Button onClick={() => pushLoadPage("home")}>Ferðir</Button>
      <p>Kennitala: 268820-6255</p>
      <p>Fjallandi ehf.</p>
      <p>Tölvupóstur: fjallandi@info.is</p>
      <p>Sími: +354 8536999</p>
    </div>
  );
}
