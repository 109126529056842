const palette = {
  jblue: "#5389ee",
  jblueDark: "#115fee",
  jblueLight: "#dce7fa",
  jbluel: "#d7e3f8",
  jgreen: "#12db34",
  jgold: "#ffd706",
  jgoldl: "#ffe260",
  jgreyd: "#747272",
  jgreym: "#929292",
  jgreyl: "#ebe9e9",
  jgreyml: "#bebdbdd5",
  jwhite: "#fff",
  jpink: "#f018cc",
  jpurple: "#b616f5",
  jred: "#e91717",
  jorange: "#fdb15b",
  jblack: "#000",
};

export const theme = {
  fontFamilies: {
    Roboto_300: "Roboto_300",
    Roboto_700: "Roboto_700",
    Roboto_Reg: "Roboto_Reg",
    Poppins_Medium: "Poppins_Medium",
    Poppins_Thin: "Poppins-Thin",
    Poppins_Bold: "Poppins-Bold",
  },
  colors: {
    white: "#fff",
    background: "#fff",
    darkBackground: "#616161",
    lightText: "#929292",
    lightBackground: "#f5f5f5",
    divider: "#757575",
    text_primary: "rgba(0,0,0,0.87)",
    text_secondary: "rgba(0,0,0,0.6)",
    text_disabled: "rgba(0,0,0,0.38)",
    primary: "#5389ee",
    primary_rgba: "rgba(83, 137, 238, 1)",
    primary_dark: "#1964ef",
    primary_dark_rgba: "rgba(25, 100, 239, 1)",
    primary_light: "#8fb3fa",

    secondary: "#12db34",
    secondary_rgba: "rgba(18, 219, 52, 1)",
    secondary_dark: "#027b17",
    secondary_dark_rgba: "rgba(2, 123, 23, 1)",
    textLink: "#5389ee",
    success: "#2e7d32",
    info: "#0288d1",
    warning: "#edbe02",
    danger: "#eb445a",
    error: "#d32f2f",
    dull: "#757575",
    inherit: "inherit",
    common: "#fff",
    grey_10: "#fdfdfd",
    grey_25: "#fafafa",
    grey_50: "#f5f5f5",
    grey_100: "#eee",
    grey_200: "#e0e0e0",
    grey_300: "#bdbdbd",
    grey_400: "#9e9e9e",
    grey_500: "#757575",
    grey_600: "#616161",
    grey_700: "#424242",
    grey_800: "#212121",
    grey_900: "#111111",
  },
  actions: {
    hover: "0.96",
    focus: "0.88",
    disabled: "0.38",
    selected: "0.92",
    main: "0.9",
    dark: "1",
    light: "0.8",
  },
  spacing: {
    s: 4,
    m: 8,
    l: 16,
  },
  borderVariants: {
    none: {
      borderWidth: 0,
    },
    s: {
      borderWidth: 1,
      borderColor: palette.jgreyd,
    },
    l: {
      borderWidth: 3,
      borderColor: palette.jgreyd,
    },
  },
  buttonWidths: {
    fitText: {},
    full: { maxWidth: "100%" },
    l: { maxWidth: 250 },
    m: { maxWidth: 150 },
    s: { maxWidth: 80 },
  },
  sizeIcon: {
    lg: 24,
    md: 19,
    sm: 17,
  },
  sizeIconCircle: {
    lg: 13,
    md: 11,
    sm: 10,
  },
  sizeText: {
    lg: 20,
    md: 16,
    sm: 13,
  },
  padding: {
    horizontal: {
      md: "6%",
    },
  },
};

export type type_size = keyof typeof theme.sizeIcon;
export const size_ionic = {
  large: "large",
  default: "default",
  small: "small",
};
export type type_size_io = keyof typeof size_ionic;
export const sizeToIonic = {
  lg: "large",
  md: "default",
  sm: "small",
};

export const icon_slot = {
  start: "start",
  end: "end",
  "icon-only": "icon-only",
};

export type type_icon_slot = keyof typeof icon_slot;

export const color_io = {
  primary: "primary",
  secondary: "secondary",
  dull: "tertiary",
  success: "success",
  warning: "warning",
  danger: "danger",
  light: "light",
  medium: "medium",
  dark: "dark",
  default: "default",
};
export type type_color_io = keyof typeof color_io;

export const darkTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    background: palette.jblack,
    foreground: palette.jwhite,
  },
};

export function ratingColor(rating: number) {
  if (rating > 10) return "green";
  if (rating > 7) return "lightgreen";
  if (rating > 4) return theme.colors.warning;
  return theme.colors.error;
}
