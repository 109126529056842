import { aT, success } from "../../../globals/constantsGlobal";

export interface ISesson {
  isEng: boolean;
  next: any;
}

export type type_session = "isEng" | "next";

const initialState = {
  isEng: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case aT.common.store.ADD_TO_SESSION_STORE: {
      console.log(
        "inside staffReducer ADD_TO_STAFF_STORE payload",
        JSON.stringify(action)
      );
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const getSession = (state: any): ISesson => {
  return state.login.sessionReducer;
};

export const getIsEng = (state: any): boolean => {
  return state.login.sessionReducer.isEng;
};
