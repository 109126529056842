import React from "react";
import JBox from "../texting/JBox";
import { Button, Chip, IconButton, Link, SvgIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getSession } from "../../redux/common/reducers/sessionReducer";
import { actionAddToStore } from "../../redux/common/actions/staffActions";

export default function LanguageSel() {
  const session = useSelector(getSession);
  const dispatch = useDispatch();
  function changeLangueage(isEng: boolean) {
    dispatch(actionAddToStore("isEng", isEng));
  }
  return (
    <div>
      <JBox variant="row_start">
        <Chip
          label="En"
          onClick={() => changeLangueage(true)}
          variant={session.isEng ? "filled" : "outlined"}
        />
        <p>|</p>
        <Chip
          label="Is"
          onClick={() => changeLangueage(false)}
          variant={session.isEng ? "outlined" : "filled"}
        />
      </JBox>
    </div>
  );
}
