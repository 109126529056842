import { Image } from "@mui/icons-material";
import React from "react";
import JTextSupTitle from "../texting/JTextSupTitle";
import "./TheTeam.css";

import JMenuManager from "../home/JMenuManager";
import { useNavigate } from "react-router-dom";
import JBox from "../texting/JBox";
import { getBigString } from "../styleUtil";
import { theme } from "../styleConst";
import { Divider, Grid, TextareaAutosize } from "@mui/material";
import { lightGreen } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { getSession } from "../../redux/common/reducers/sessionReducer";
import JTourSplitTitle from "../texting/JTourSplitTitle";
import JTourSubSplit from "../texting/JTourSubSplit";

const url_hjalti =
  "https://res.cloudinary.com/jafna/image/upload/v1707838914/team/20220903_164832_1_hzaxni.jpg";

const url_hrund =
  "https://res.cloudinary.com/jafna/image/upload/v1708532282/team/hrundscreen_crfmfg.png";

export default function TheTeam() {
  const navigate = useNavigate();
  const session = useSelector(getSession);
  const handleOnClick = () => navigate("/home");

  function getHAText(isEng: boolean) {
    return (
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 32,
          backgroundColor: "lightgreen",
          wordWrap: "break-word",
          zIndex: 99,
        }}
      >
        <div style={{ paddingRight: 32 }}>
          {isEng ? (
            <>
              <p>
                Hjalti founded Fjallanda in February 2024. He is, however no
                newcomer to planing trips, as he has organized group trips since
                2007 for himself and his friends. The first trip that he
                organized a heli-skiing trip to Kyrgyzstan in 2007. He loves the
                outdoors, especially skiing and cycling. Hjalti grew up on skis
                and is equal in skiing, snowboarding or the biggest passion
                Telemark skiing. Why start a tourism business? To get more
                people to enjoy the wonderful places that Hjalti has come to
                know in Iceland.
              </p>
              <p>
                Hjalti is a civil engineer, master from Danmarks Tekniske
                University - DTU year 1997. He has worked evenly as a software
                developer and in the financial sector. With Fjallanda he wants
                to try something completely new and get away from the desk. He
                has three wonderful children with his wife Ögla.
              </p>
            </>
          ) : (
            <>
              <p>
                Hjalti stofnaði fyrir tækið Fjallanda í febrúar 2024. Hann er þó
                enginn nýgræðingur í ferðamennsku, enda hefur hann skiplagt
                hópferðir síðan 2007 fyrir sig og sína félaga. Fyrsta ferðin sem
                hann skiplagði var þyrluskíðaferði til Kyrkistan árið 2007. Hann
                elskar útivist og þá sérstaklega skíði og hjól. Hjalti ólst upp
                á skíðum og er jafnvígur í skíði, bretti nú eða mestu ástríðuna
                Telemark skiðun. Af hverju að stofna ferðaþjónustu? Til að
                fleiri fái að njóta þeirra frábæru staða sem Hjalti hefur kynnst
                á sínum ferðalögum.
              </p>

              <p>
                Hjalti er verkfræðingur að mennt, master frá Danmarks Tekniske
                University - DTU árið 1997. Hjalti hefur unnið jöfnum höndum í
                hugbúnaðargerð og í fjármálageiranum. Með Fjallanda langar hann
                að prufa eitthvað alveg nýtt og komast frá skrifborðinu. Hann á
                þrjú yndisleg börn með konunni sinni Öglu, og eru þau öll mikið
                fyrir útvist, skíði gönguferðir.
              </p>
            </>
          )}
        </div>
      </div>
    );
  }

  function getHrundText(isEng: boolean) {
    return (
      <div
        style={{
          paddingLeft: 16,
          paddingRight: 32,
          backgroundColor: "lightgreen",
          wordWrap: "break-word",
          zIndex: 99,
        }}
      >
        <div style={{ paddingRight: 32 }}>
          {isEng ? (
            <>
              <p>
                Hrund is the co-founder of the company and also Hjalti's syster.
                Hrund has a master's degree in art and graduated in 2008 from
                the Gerrit Rietveld Academie in the Netherlands, see
                <a href="https://www.hrund.org/"> work done by her</a>. <br />{" "}
                <br />
                Hrund is a keen outdoor person and has worked on projects linked
                to nature conservation. Hrund has multible talents and has
                worked as a ranger, and is a licenced tourist driver. Hrund
                comes with an artistic eye the company while Hjalti is more in
                the numbers.
              </p>
            </>
          ) : (
            <>
              <p>
                Hrund er meðstofnandi af Fjallanda og jafnframt systir Hjalta.
                Hrund er með master í myndlist og útskrifaðist árið 2008 frá
                Gerrit Rietveld Academie í Hollandi, sjá
                <a href="https://www.hrund.org/"> verk eftir hana</a>. <br />
                <br />
                Hrund er mikil útivistamanneskja og hefur unnið í verkefnum
                tengd náttúrvernd. Hrund hefur reynslu sem landvörður, og einnig
                réttindi til að keyra túrista. Hrund kemur með listræna augað á
                fyrirtækið á meðan Hjalti er meira í tölunum.
              </p>
            </>
          )}
        </div>
      </div>
    );
  }

  function getImage() {
    return (
      <div
        style={{
          width: 500,
          height: 680,
          padding: 10,
        }}
      >
        <img
          src={getBigString(url_hjalti, "c_scale,w_500/")}
          alt="Mynda af stofnandanum Hjalta"
          width={500}
        ></img>
      </div>
    );
  }
  function getImageHrund() {
    return (
      <div
        style={{
          width: 500,
          height: 680,
          padding: 10,
        }}
      >
        <img
          src={getBigString(url_hrund, "c_scale,w_500/")}
          alt="Mynda af stofnandanum Hjalta"
          width={500}
        ></img>
      </div>
    );
  }
  return (
    <div>
      <JMenuManager></JMenuManager>
      <JBox variant="page">
        <JTourSplitTitle>
          {session.isEng ? "The company" : "Fyrirtækið"}
        </JTourSplitTitle>
        <JBox variant="text_box">
          {!session.isEng ? (
            <p>
              Fjallandi er nýstofnað ferðaþjónustufyriræki. Markmið Fjallanda er
              að einbeita sér að fáum en vönduðum ferðum þar sem náttúran er í
              fyrirrúmi. Ferðir Fjallanda eiga að vera einstakar og fjarri massa
              túrisma.
            </p>
          ) : (
            <p>
              Fjallandi is a newly established tourism business. Fjallanda's
              goal is to focus on few quality trips where nature is in priority,
              Fjallanda's journeys should be unique and away from the mass
              tourism.
            </p>
          )}
        </JBox>

        <JTourSplitTitle>
          {session.isEng ? "The team" : "Teymið"}
        </JTourSplitTitle>

        <JTourSubSplit>Hjalti Atlason</JTourSubSplit>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <JBox variant="center">{getImage()}</JBox>
          </Grid>
          <Grid item xs={12} md={6}>
            {getHAText(session.isEng)}
          </Grid>
        </Grid>
        <Divider />
        <JTourSubSplit>Hrund Atladóttir</JTourSubSplit>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <JBox variant="center">{getImageHrund()}</JBox>
          </Grid>
          <Grid item xs={12} md={6}>
            {getHrundText(session.isEng)}
          </Grid>
        </Grid>
      </JBox>
    </div>
  );
}
