/* eslint-disable @typescript-eslint/no-inferrable-types */
import { take, put, all, takeLatest, select } from "redux-saga/effects";

import { aT, createAction } from "../../../globals/constantsGlobal";

export function* loadPageManager(action) {
  console.log(
    `Calling: loginSagas loadPageManager, incoming:  ${JSON.stringify(action)}`
  );

  let viewKey = action.payload;

  //history.push("/" + viewKey);
}

export function* watchLoadPage() {
  yield takeLatest(aT.common.man.LOAD_PAGE_MAN, loadPageManager);
}

function* rootSaga() {
  yield all([watchLoadPage()]);
}

export default rootSaga;
