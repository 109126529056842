import { Close } from "@mui/icons-material";
import { Button, Divider, TextField } from "@mui/material";
import React, { useState } from "react";
import JBox from "../texting/JBox";
import JTextSupTitle from "../texting/JTextSupTitle";
import "./JOrderForm.css";
import { formatCurrencyDots } from "../styleUtil";
interface JO {
  pushClose: () => void;
  trip: string;
  numPeople: number;
  price: number;
}

export default function JOrderForm({ trip, numPeople, price, pushClose }: JO) {
  const [first, setfirst] = useState("");
  const [last, setlast] = useState("");
  const [tel, settel] = useState("");
  const [city, setcity] = useState("");
  const [address, setaddress] = useState("");
  const [email, setemail] = useState("");
  const [emailError, setemailError] = useState(false);
  const validationEmail = (text: string) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regex.test(text) === true;
  };

  function handleEmail(val: string) {
    const isValid = validationEmail(val);
    if (isValid === false) {
      setemail("");
      return setemailError(true);
    }
    setemailError(false);
    setemail(val);
  }

  return (
    <div>
      <JBox variant="row">
        <div style={{ flex: 10 }}></div>
        <div style={{ flex: 1 }}>
          <JBox variant="center">
            <Close onClick={() => pushClose()}></Close>
          </JBox>
        </div>
      </JBox>
      <JTextSupTitle>
        Fjöldi í ferð: {numPeople}
        <h3>Verð {formatCurrencyDots(price * numPeople)}</h3>
      </JTextSupTitle>

      <JBox variant="row_wrap">
        <div className="order_input">
          <TextField
            id="outlined-basic"
            label="Fornafn"
            variant="outlined"
            required={true}
            type="text"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setfirst(ev.target.value)
            }
          />
        </div>
        <div className="order_input">
          <TextField
            id="outlined-basic"
            label="Eftirnafn"
            variant="outlined"
            required={true}
            type="text"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setlast(ev.target.value)
            }
          />
        </div>
        <div className="order_input">
          <TextField
            id="outlined-basic"
            label="Tölvupóstur"
            variant="outlined"
            required={true}
            type="email"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              handleEmail(ev.target.value)
            }
            helperText={emailError ? "Villa í tölvupósti" : ""}
            color={emailError ? "error" : "success"}
          />
        </div>
        <div className="order_input">
          <TextField
            id="outlined-basic"
            label="Heimilisfang"
            variant="outlined"
            required={true}
            type="text"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setaddress(ev.target.value)
            }
          />
        </div>
        <div className="order_input">
          <TextField
            id="outlined-basic"
            label="Bær"
            variant="outlined"
            required={true}
            type="text"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              setcity(ev.target.value)
            }
          />
        </div>
        <div className="order_input">
          <TextField
            id="outlined-basic"
            label="Sími"
            variant="outlined"
            required={true}
            type="tel"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              settel(ev.target.value)
            }
          />
        </div>
      </JBox>
      <Divider></Divider>
      <div style={{ paddingTop: 20 }}>
        <JBox variant="row_around">
          <Button variant="outlined" onClick={() => () => pushClose()}>
            Hætta við
          </Button>
          <Button
            variant="contained"
            disabled={
              first === "" ||
              last === "" ||
              city === "" ||
              email === "" ||
              tel === "" ||
              address === ""
            }
          >
            Áfram í örugga borgun
          </Button>
        </JBox>
      </div>
    </div>
  );
}
