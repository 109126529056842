import { Divider } from "@mui/material";
import React from "react";

interface JT {
  children: string;
}

export default function JMainSplitTitle({ children }: JT) {
  return (
    <>
      <Divider />
      <h2 style={{ fontStyle: "italic", paddingLeft: 16 }}>{children}</h2>
    </>
  );
}
