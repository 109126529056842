import { theme } from "./styleConst";

export function formatShortDate(date: Date) {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function formatShortDateStr(date: string) {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function round(value: number, precision: number) {
  const multiplier = Math.pow(10, precision || 0);
  const rounded = Math.round(value * multiplier) / multiplier;
  return rounded.toFixed(precision);
}

export const formatPercent = (
  num: string,
  precision = 1,
  showSymbol = true
) => {
  const inum = parseFloat(num);
  return `${inum.toFixed(precision)}${showSymbol ? "%" : ""}`;
};

export const formatPercentNum = (
  number: number,
  precision = 1,
  showSymbol = true
) => {
  return `${number.toFixed(precision)}${showSymbol ? "%" : ""}`;
};

export const formatCurrency = (
  number: string,
  showSymbol: true,
  round: true
) => {
  let inum = parseFloat(number);
  if (round) inum = Math.round(inum);
  return `${inum.toLocaleString("de-DE")}${showSymbol ? " kr" : ""}`;
};
export const formatCurrencyNum = (
  number: number,
  showSymbol = true,
  round = true
) => {
  let inum = number;
  if (round) inum = Math.round(number);
  return `${inum.toLocaleString("de-DE")}${showSymbol ? " kr." : ""}`;
};

export const formatNumberDots = (number: number, round = true) => {
  let inum = number;
  if (round) inum = Math.round(number);
  return `${inum.toLocaleString("de-DE")}${""}`;
};

export const floatToPercent = (val: string, numextra = 1): string => {
  return `${parseFloat(val).toFixed(numextra)}%`;
};

export function numberWithDots(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function intNumberWithDots(x: number) {
  return Math.trunc(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const formatCurrencyDots = (number: number, showSymbol = true) => {
  return `${intNumberWithDots(number)}${showSymbol ? " kr." : ""}`;
};

export const shadow = ({
  color = theme.colors.divider,
  opacity = 0.5,
  radius = 30,
  offsetWidth = 7,
  offsetHeight = 8,
}) => {
  return {
    shadowColor: color,
    shadowOpacity: opacity,
    shadowRadius: radius,
    shadowOffset: {
      width: offsetWidth,
      height: offsetHeight,
    },
  };
};

export const shadowMini = ({
  color = theme.colors.grey_300,
  opacity = 0.5,
  radius = 30,
  offsetWidth = 2,
  offsetHeight = 2,
}) => {
  return {
    shadowColor: color,
    shadowOpacity: opacity,
    shadowRadius: radius,
    shadowOffset: {
      width: offsetWidth,
      height: offsetHeight,
    },
  };
};

export function getBigString(
  orgString: string,
  toInsert = "c_scale,w_600,h_350/"
) {
  // Position to add string
  let posIndex = orgString.indexOf("upload");
  let indexPosition = posIndex + 7;

  // Using slice method to split string
  const newString =
    orgString.slice(0, indexPosition) +
    toInsert +
    orgString.slice(indexPosition);
  console.log("newstring", newString);
  return newString;
}
