import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Home.css";
import JMenuManager from "./JMenuManager";
import { Button, Divider, css, styled } from "@mui/material";
import TBudarholl from "../bhski/TBudarholl";
import TLatrar from "../latrar/TLatrar";
import TripOview from "../tripOview/TripOview";
import { Modal as BaseModal } from "@mui/base/Modal";
import JOrderForm from "../personuform/JOrderForm";
import clsx from "clsx";
import JBox from "../texting/JBox";
import BottomPage from "./BottomPage";
import { loadPageManager } from "../../redux/common/actions/loginSagas";
import { type_page } from "./JMenuBar";
import { useDispatch } from "react-redux";

const url_forsida =
  "https://res.cloudinary.com/jafna/image/upload/v1708509162/team/forsidurmynd_kj5avq.png";

export default function Home() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [trip, settrip] = useState("");
  const [numPeople, setnumPeople] = useState<number>(1);
  const [totalPrice, settotalPrice] = useState<number>(0);
  const [toursel, settoursel] = useState("");

  function handleSelTour(val: string) {
    settoursel(val);
    window.scrollTo(0, 0);
  }
  function getSelTour() {
    if (toursel === "bud")
      return (
        <TBudarholl
          pushOrder={(trip, numPeople, totalPrice) =>
            handlePushOrder(trip, numPeople, totalPrice)
          }
        />
      );
    if (toursel === "latrar")
      return (
        <TLatrar
          pushOrder={(trip, numPeople, totalPrice) =>
            handlePushOrder(trip, numPeople, totalPrice)
          }
        />
      );
    return <></>;
  }

  function handlePushOrder(trip: string, nump: number, totalp: number) {
    settrip(trip);
    setnumPeople(nump);
    settotalPrice(totalp);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ width: "100%", height: 200 }}>
        <JMenuManager></JMenuManager>
      </div>
      <JBox variant="page">
        <Modal
          aria-labelledby="unstyled-modal-title"
          aria-describedby="unstyled-modal-description"
          open={open}
          onClose={() => handleClose()}
          slots={{ backdrop: StyledBackdrop }}
        >
          <ModalContent sx={{ width: 780 }}>
            <JOrderForm
              trip={trip}
              numPeople={numPeople}
              price={totalPrice}
              pushClose={() => handleClose()}
            ></JOrderForm>
          </ModalContent>
        </Modal>
        {getSelTour()}
        <TripOview pushSelTour={(tour: string) => handleSelTour(tour)} />
        <Divider />
        <div className="bottomPage">
          <BottomPage pushLoadPage={(val: type_page) => loadPageManager(val)} />
        </div>
      </JBox>
    </div>
  );
}

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Backdrop = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "base-Backdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});
const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;
const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0 4px 12px
      ${theme.palette.mode === "dark" ? "rgb(0 0 0 / 0.5)" : "rgb(0 0 0 / 0.2)"};
    padding: 24px;
    color: ${theme.palette.mode === "dark" ? grey[50] : grey[900]};

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: ${theme.palette.mode === "dark" ? grey[400] : grey[800]};
      margin-bottom: 4px;
    }
  `
);
