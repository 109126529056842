import { createStore, applyMiddleware } from "redux";

import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";
import thunk from "redux-thunk";
// import promise from "redux-promise-middleware"
import reducer from "./globals/index";

import rootSaga from "./globals/sagas";

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducer, applyMiddleware(sagaMiddleware, thunk));
sagaMiddleware.run(rootSaga);

export default store;
