import React from "react";
import JMenuManager from "../home/JMenuManager";
import JBox from "../texting/JBox";
import JTextSupTitle from "../texting/JTextSupTitle";
import { useSelector } from "react-redux";
import { getIsEng } from "../../redux/common/reducers/sessionReducer";

export default function InqueryPage() {
  const isEng = useSelector(getIsEng);
  return (
    <div>
      <JMenuManager></JMenuManager>
      <JBox variant="page">
        <JTextSupTitle>{isEng ? "Contact" : "Fyrirspurnir"}</JTextSupTitle>
        <JBox>
          <p>
            {isEng ? "We wan't to hear from you!" : "Við viljum heyra í þér!"}
          </p>
          <p>{isEng ? "Email" : "Tölvupóstur"}: fjallandi@info.is</p>
          <p>{isEng ? "GSM" : "Sími:"} +354 8536999</p>
        </JBox>
      </JBox>
    </div>
  );
}
