import React from "react";
import JBox from "../texting/JBox";
import JTextTitle from "../texting/JTextTitle";
import { Button, IconButton } from "@mui/material";
import { MenuRounded } from "@mui/icons-material";
import { theme } from "../styleConst";
import LanguageSel from "./LanguageSel";
import { useSelector } from "react-redux";
import { getSession } from "../../redux/common/reducers/sessionReducer";

interface JM {
  pushOpenMenu: (val: boolean) => void;
  pushSelPage: (val: type_page) => void;
}

export type type_tour = "latrar" | "bud";
export type type_page = "home" | "team" | "contact";

export default function JMenuBar({ pushOpenMenu, pushSelPage }: JM) {
  const session = useSelector(getSession);
  const [isOpen, setisOpen] = React.useState(false);
  const [trip, settrip] = React.useState("");

  return (
    <div style={{ width: "100%" }}>
      <div className="onlyMobile">
        <div className="menuBar">
          <JBox variant="row">
            <div style={{ flex: 1 }}>
              <JBox variant="center">
                <IconButton
                  aria-label="menu"
                  onClick={() => pushOpenMenu(true)}
                >
                  <MenuRounded></MenuRounded>
                </IconButton>
              </JBox>
            </div>

            <div style={{ flex: 10 }}>
              <JBox variant="center">
                <JTextTitle>Fjallandi</JTextTitle>
              </JBox>
            </div>
          </JBox>
        </div>
      </div>
      <div className="onlyDesktop">
        <div className="menuBar">
          <JBox variant="row">
            <div style={{ flex: 2, backgroundColor: "green" }}>
              <JBox variant="center">
                <JTextTitle>Fjallandi</JTextTitle>
              </JBox>
            </div>
            <div style={{ flex: 3 }}></div>

            <div style={{ flex: 3 }}>
              <Button onClick={() => pushSelPage("home")}>
                {session.isEng ? "Our trips" : "Ferðir"}
              </Button>
            </div>
            <div style={{ flex: 3 }}>
              <Button onClick={() => pushSelPage("team")}>
                {session.isEng ? "About us" : "Um okkur"}
              </Button>
            </div>
            <div style={{ flex: 3 }}>
              <Button onClick={() => pushSelPage("contact")}>
                {session.isEng ? "Contact" : "Hafa samband"}
              </Button>
            </div>

            <div style={{ flex: 2 }}>
              <LanguageSel />
            </div>
          </JBox>
        </div>
      </div>
    </div>
  );
}
