import React from "react";
import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "./image-gallery.scss";
import "react-calendar/dist/Calendar.css";

import date1 from "./dags_1_6_mai.png";

import latrar_2018_fjara_bjorgunar from "./latrar_2018_fjara_bjorgunar.png";
import latrar_a_top_margir_solskin_2018 from "./latrar_a_top_margir_solskin_2018.png";
import "react-image-gallery/styles/css/image-gallery.css";
import JBox from "../texting/JBox";
import { Chip, Grid, List, ListItem, ListItemText } from "@mui/material";
import TPrice from "../dateprice/TPrice";
import JTourSplitTitle from "../texting/JTourSplitTitle";
import JTourSubSplit from "../texting/JTourSubSplit";
import JTextTitle from "../texting/JTextTitle";
import DatePicker from "react-datepicker";
import Calendar from "react-calendar";

export const v_images_latrar = [
  {
    key: "directions",
    src: "https://res.cloudinary.com/jafna/image/upload/v1707741343/latrar/directions_korz1j.png",
  },
  {
    key: "batur",
    src: "https://res.cloudinary.com/jafna/image/upload/v1707734021/latrar/2019-04-25_13.17.12_vfuiqv.jpg",
  },
  {
    key: "sjor",
    src: "https://res.cloudinary.com/jafna/image/upload/v1707733488/latrar/2021-04-29_10.13.21_weuqsd.jpg",
  },
  {
    key: "strönd",
    src: "https://res.cloudinary.com/jafna/image/upload/v1707734022/latrar/2018-04-15_11.54.38_vmsm61.jpg",
  },
  {
    key: "hus_hlid",
    src: "https://res.cloudinary.com/jafna/image/upload/v1707733491/latrar/2021-04-29_11.05.57_ptcmov.jpg",
  },
  {
    key: "toppur",
    src: "https://res.cloudinary.com/jafna/image/upload/v1707734020/latrar/2018-04-14_15.48.40_dbbldu.jpg",
  },
  {
    key: "Skida_nidur_sjor",
    src: "https://res.cloudinary.com/jafna/image/upload/v1707741642/latrar/latrar_skida2_nknvhz.png",
  },
];

//res.cloudinary.com/jafna/image/upload/v1707741642/latrar/latrar_skida2_nknvhz.png
//res.cloudinary.com/jafna/image/upload/v1707740981/latrar/directions_icv6ce.png

//key: "telemark",
//src: "https://res.cloudinary.com/jafna/image/upload/v1707733489/latrar/2021-05-08_12.23.20_dmm260.jpg",

const unitPrice = 349 * 1000;
const v_dates = ["1-6 maí 2024"];

interface IO {
  pushOrder: (trip: string, numPeople: number, totalPrice: number) => void;
}

export default function TLatrar({ pushOrder }: IO) {
  const [numDate, setnumDate] = React.useState<number>(0);
  function getBigString(orgString: string) {
    const toInsert = "c_scale,w_600,h_350/";
    // Position to add string
    let posIndex = orgString.indexOf("upload");
    let indexPosition = posIndex + 7;

    // Using slice method to split string
    const newString =
      orgString.slice(0, indexPosition) +
      toInsert +
      orgString.slice(indexPosition);
    console.log("newstring", newString);
    return newString;
  }
  function getBig() {
    let v_im: { original: string }[] = [];
    v_images_latrar.forEach((element) => {
      const imstr = getBigString(element.src);

      v_im.push({ original: imstr });
    });
    return v_im;
  }
  const v_utb_utan = [
    "Skíði/bretti",
    "Skíðastafir",
    "Skíða/bretta skór",
    "Bakpoki",
    "Skinn",
    "Skóflu",
    "Ýli",
    "Snjóflóðastöng",
    "Skíðahjálm",
    "Skíðagleiraugu",
    "Sólgleraugu",
  ];
  const v_utb_fot = [
    "Vatnsheldur Jakki",
    "Vatnheldar buxur",
    "Ullar nærföt tvö sett",
    "Ullar peysa",
    "Skíðasokkar tvö pör",
    "Húfu",
    "buff",
    "Skíðahanska/Lúffur",
    "Þunna hanska",
    "Dúnúlpa",
  ];
  const v_utb_skali = [
    "Inniskó",
    "Svefnpoka",
    "Kodda",
    "Snyrtivörur",
    "Naglaklippur",
    "Sólarvörn",
    "Sundskýla/bolur",
    "Þvottapoki",
    "Hleðslubanka",
  ];

  function getUtbunadur() {
    const fot = v_utb_fot.map((item, index) => {
      return <div>{item}</div>;
    });
    const skali = v_utb_skali.map((item, index) => {
      return <div>{item}</div>;
    });
    const skidi = v_utb_utan.map((item, index) => {
      return <div>{item}</div>;
    });
    return (
      <JBox variant="row_wrap">
        <div style={{ width: 300, minWidth: 300 }}>
          <JTourSubSplit>Skíði</JTourSubSplit> {skidi}
        </div>
        <div style={{ width: 300, minWidth: 300 }}>
          <JTourSubSplit>Föt</JTourSubSplit> {fot}
        </div>
        <div style={{ width: 300, minWidth: 300 }}>
          <JTourSubSplit>Skáli</JTourSubSplit> {skali}
        </div>
      </JBox>
    );
  }
  function getInnifalid() {
    return (
      <>
        <JTourSplitTitle children="Innifalið" />
        <p> Gisting í skála</p>
        <p> Gisting ferð með bát</p>
        <p> Far til og frá Akureyrarflugvelli</p>
        <p> Allur matur á meðan ferð stendur</p>
        <p> Fimm kvöldverðir (1-5 maí)</p>
        <p> Fimm morgunverðir (2-6)</p>
        <p> Smurt í fjallið (1-5)</p>
        <JTourSplitTitle children="Ekki innifalið" />
        <p>Drykkir</p>
        <p>Ferðalag til Akureyrar eða Dalvík</p>
      </>
    );
  }
  const [startDate, setStartDate] = React.useState(new Date("05/01/2024"));
  return (
    <div style={{ padding: 16 }}>
      <JTextTitle>Látrar skáli - fjallaskíði</JTextTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <p>
            One of the most snow heavy and remote place in Iceland with access
            to sea. The Látar capin is truly in the middle of nower with over 15
            km to next road in Grenivík. The mounains are right behind the capin
            and the skiing there is unique.
          </p>
          <div
            style={{
              width: "100%",
              height: 350,
              backgroundColor: "",
              overflow: "hidden",
            }}
          >
            <JBox variant="center">
              <ImageGallery items={getBig()} />;
            </JBox>
          </div>
          {getInnifalid()}
          <JTourSplitTitle children="Útbúnaður" />
          {getUtbunadur()}
          <JTourSplitTitle children="Dagskrá" />
          <JTourSubSplit children="Dagur 1" />
          <p>Mæting á Dalvík klukkan 14:00</p>
          <p>Fordrykkur</p>
          <p>Grillaður, kjúklingur</p>
          <JTourSubSplit children="Dagur 2-5" />
          <p>Kaffi og upphitun fyrir daginn, teygjur öndunaræfingar</p>
          <p>Staðgóður morgunmatur og smurt fyrir fjallið</p>
          <p>Lagt af stað, ef við förum í bíl þá taka með sunddót</p>
          <p>
            Eftir 1000 til 1800 metra fjallaskíði er farið beint í sund á
            Ólafsfirði
          </p>
          <p>Eftir það býður lambalæri/holusteik</p>
          <p>Eftir kvöldmat er frjáls tími</p>
          <JTourSubSplit children="Dagur 6" />
          <p>Kaffi og upphitun fyrir daginn, teygjur öndunaræfingar</p>
          <p>Staðgóður morgunmatur og smurt fyrir fjallið</p>
          <p>Hádegisverður í fjallinu súpa og brauð</p>
          <p>Skutla á Akureyrarflugvöll</p>
          <JTourSplitTitle children="Afbókun/Endurgreiðsla" />
          <p>Ef afbókað fyrir 1. apríl, þá 50% endurgreiðla</p>
          <p>Lágmarksfjöldi í ferð er 5</p>
        </Grid>
        <Grid item xs={12} md={4}>
          <JTourSplitTitle children="Dagsettningar" />
          <div className="date-price-page">
            <div style={{ width: "100%" }}>
              <JBox variant="row_start">
                <div style={{ padding: 5 }}>
                  <Chip
                    label={v_dates[0]}
                    onClick={() => setnumDate(0)}
                    variant={numDate === 0 ? "filled" : "outlined"}
                  />
                </div>
              </JBox>
            </div>
            <div style={{ width: "100%", padding: 0 }}>
              <TPrice
                unitPrice={unitPrice}
                pushUnits={(num) => pushOrder("bud", num, num * unitPrice)}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
