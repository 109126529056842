import { aT } from "../../../globals/constantsGlobal";
import { type_session } from "../reducers/sessionReducer";

export function actionLoadPage(viewKey: string) {
  return {
    type: aT.common.man.LOAD_PAGE_MAN,
    payload: viewKey,
  };
}

export function actionAddToStore(field: type_session, value: boolean | string) {
  /* field, value */
  interface Foo {
    [key: string]: boolean | string;
  }
  let foo: Foo = {};
  foo[field] = value;

  return {
    type: aT.common.store.ADD_TO_SESSION_STORE,
    payload: foo,
  };
}
