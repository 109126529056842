import React from "react";

import hus_bilar from "./hus_bilar.png";
import JTourSplitTitle from "../texting/JTourSplitTitle";
import {
  Button,
  Chip,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import JTourSubSplit from "../texting/JTourSubSplit";
import { Add, AddCircleOutline } from "@mui/icons-material";
import TPrice from "../dateprice/TPrice";
import JBox from "../texting/JBox";
import JTextTitle from "../texting/JTextTitle";

interface IO {
  pushOrder: (trip: string, numPeople: number, totalPrice: number) => void;
}
const unitPrice = 199 * 1000;
const v_dates = ["20-23 apríl", "24-27 apríl"];

export default function TBudarholl({ pushOrder }: IO) {
  const [numDate, setnumDate] = React.useState<number>(0);
  return (
    <div style={{ padding: 16 }}>
      <JTextTitle>Búðarhóll - fjallaskíði</JTextTitle>
      <div>
        <p>
          Búðarhóll er í hjarta Tröllaskagans. Hið fallega fjall Finnurinn
          gnæfir yfir, þannig að það er hægt að byrja að skíða frá bæjarhlaðinu.
          Búðarhóll er alveg á sjávarbakkanum, og er kyrrðin þar á morgnana og
          kvöldin ómótstæðileg. Örfár mínútur eru síðan í Héðinsfjörð og
          Múlkolluna þannig að það er á nógu á taka allt í kringum húsið.
        </p>
        <p>
          <img
            src={hus_bilar}
            alt="Mynd af húsi og bílum"
            width={300}
            style={{ overflow: "hidden" }}
          ></img>
          Búðarhóll er yfir hundrað ára gamalt hús, það er staðsett ásamt
          nokkrum húsum á stað sem heitir Kleifar. Kleifarnar ásamt sambærilegum
          byggðum var upphafið af því að almenningur komst undan offurvaldi
          bændastéttarinnar. Kleifarnar samanstóðu af svokölluðum sjávarbændum,
          en þar nýttu menn sjóinn til skapa tekjur og þurftu þannig minna
          jarðnæði.
        </p>
      </div>
      <JTourSplitTitle children="Útbúnaður" />;
      <nav aria-label="secondary mailbox folders">
        <List>
          <ListItem>
            <ListItemText primary="Skíði" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Skíðastafir" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Hjálmur" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Ýlir" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Snjóflóðastöng" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Snjóflóðaskófla" />
          </ListItem>
          <ListItem>
            <ListItemText primary="Snjóflóðaskófla" />
          </ListItem>
        </List>
      </nav>
      <JTourSplitTitle children="Dagskrá" />
      <JTourSubSplit children="Dagur 1" />
      <p>Mæting á Búðarhól klukkan 16:00</p>
      <p>Fordrykkur</p>
      <p>Grillaður, kjúklingur</p>
      <JTourSubSplit children="Dagur 2" />
      <p>Kaffi og upphitun fyrir daginn, teygjur öndunaræfingar</p>
      <p>Staðgóður morgunmatur og smurt fyrir fjallið</p>
      <p>Lagt af stað, ef við förum í bíl þá taka með sunddót</p>
      <p>
        Eftir 1000 til 1800 metra fjallaskíði er farið beint í sund á Ólafsfirði
      </p>
      <p>Eftir það býður lambalæri/holusteik</p>
      <p>Eftir kvöldmat er frjáls tími</p>
      <JTourSubSplit children="Dagur 3" />
      <p>Kaffi og upphitun fyrir daginn, teygjur öndunaræfingar</p>
      <p>Staðgóður morgunmatur og smurt fyrir fjallið</p>
      <p>Hádegisverður í fjallinu súpa og brauð</p>
      <p>Skutla á Akureyrarflugvöll</p>
      <JTourSplitTitle children="Afbókun/Endurgreiðsla" />
      <p>Ef afbókað fyrir 1. apríl, þá 50% endurgreiðla</p>
      <p>Lágmarksfjöldi í ferð er 5</p>
      <JTourSplitTitle children="Dagsettningar" />
      <JBox variant="row_start">
        <div style={{ padding: 5 }}>
          <Chip
            label={v_dates[0]}
            onClick={() => setnumDate(0)}
            variant={numDate === 0 ? "filled" : "outlined"}
          />
        </div>
        <div style={{ padding: 5 }}>
          <Chip
            label={v_dates[1]}
            onClick={() => setnumDate(1)}
            variant={numDate === 1 ? "filled" : "outlined"}
          />
        </div>
      </JBox>
      <div style={{ padding: 20 }}>
        <TPrice
          unitPrice={unitPrice}
          pushUnits={(num) => pushOrder("bud", num, num * unitPrice)}
        />
      </div>
    </div>
  );
}
