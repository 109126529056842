import React from "react";
import JTextSupTitle from "../texting/JTextSupTitle";
import JBox from "../texting/JBox";

import { Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { type_page } from "../home/JMenuBar";
import LanguageSel from "../home/LanguageSel";
import { useSelector } from "react-redux";
import { getSession } from "../../redux/common/reducers/sessionReducer";

interface SI {
  onPushClose: (page: type_page) => void;
}

export default function MenuOview({ onPushClose }: SI) {
  const session = useSelector(getSession);
  function handleNewPage(page: type_page) {
    onPushClose(page);
  }

  return (
    <div style={{ flex: 1, paddingTop: 20 }}>
      <JBox variant="row">
        <div style={{ flex: 2 }}>
          <LanguageSel />
        </div>
        <div style={{ flex: 10 }}></div>
        <div style={{ flex: 1 }}>
          <JBox variant="center">
            <IconButton>
              <Close onClick={() => onPushClose("home")}></Close>
            </IconButton>
          </JBox>
        </div>
      </JBox>
      <div style={{ paddingTop: "30px", paddingLeft: "16px" }}>
        <JBox variant="column_start">
          <Button onClick={() => handleNewPage("home")}>
            {session.isEng ? "Our trips" : "Ferðir"}
          </Button>
          <Button onClick={() => handleNewPage("team")}>
            {session.isEng ? "About us" : "Um okkur"}
          </Button>
          <Button onClick={() => handleNewPage("contact")}>
            {session.isEng ? "Contact" : "Hafa samband"}
          </Button>
        </JBox>
      </div>
    </div>
  );
}
