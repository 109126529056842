import { aT } from "./constantsGlobal";

export const getApi = (path: string) => {
  return path;
};

// request
export const actionToApi = (action: any) => {
  switch (action) {
    default:
      return "";
  }
};
