import React from "react";
import TripOviewImage from "./TripOviewImage";
import { Button, Divider } from "@mui/material";
import JBox from "../texting/JBox";
import JTourSplitTitle from "../texting/JTourSplitTitle";
import { getBigString } from "../styleUtil";
import { v_images_latrar } from "../latrar/TLatrar";
import styles from "./TripOview.module.css";
import "./TripOview.css";
import JMainSplitTitle from "../texting/JMainSplitTitle";

interface IT {
  pushSelTour: (tour: string) => void;
}

export default function TripOview({ pushSelTour }: IT) {
  return (
    <div className="oviewPage">
      <JMainSplitTitle>Ferðir í boði</JMainSplitTitle>
      <div style={{ padding: 20 }}>
        <JBox variant="row_wrap">
          <div>
            <JBox variant="center">
              <Button variant="text" onClick={() => pushSelTour("bud")}>
                <TripOviewImage
                  tour="Búðarhóll fjallaskíði"
                  unitPrice={199 * 1000}
                  duration={4}
                  src="https://res.cloudinary.com/jafna/image/upload/c_scale,h_250,w_300/v1707580424/20230930_112552_nnp9ec.jpg"
                ></TripOviewImage>
              </Button>
            </JBox>
          </div>
          <div>
            <JBox variant="center">
              <Button variant="text" onClick={() => pushSelTour("latrar")}>
                <TripOviewImage
                  tour="Látrar fjallaskíði"
                  unitPrice={299 * 1000}
                  duration={6}
                  src={getBigString(
                    v_images_latrar[2].src,
                    "c_scale,h_250,w_300/"
                  )}
                ></TripOviewImage>
              </Button>
            </JBox>
          </div>
        </JBox>
      </div>
    </div>
  );
}
