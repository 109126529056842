import { Divider } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";

interface JT {
  children: string;
}

export default function JTourSplitTitle({ children }: JT) {
  return (
    <>
      <Divider component="div" role="presentation">
        <h3>{children}</h3>
      </Divider>
    </>
  );
}
