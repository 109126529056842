import React from "react";

import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./component/home/Home";

import TheTeam from "./component/theteam/TheTeam";
import InqueryPage from "./component/inquery/InqueryPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="team" element={<TheTeam />} />
      <Route path="contact" element={<InqueryPage />} />
    </Routes>
  );
}

export default App;
