import { take, call, put, all, select } from "redux-saga/effects";
import { success, fail, getServer } from "./constantsGlobal";
import { actionToApi } from "./constantsActionToApi";
import * as api from "./api";

import loginSaga from "../redux/common/actions/loginSagas";

const requestMatcher = (action) => {
  const regex = /_REQUEST$/g;
  if (regex.exec(action) && action !== "BORROWER_REQUEST") {
    return true;
  }
  return false;
};
const submitMatcher = (action) => {
  const regex = /_POST$/g;
  if (regex.exec(action)) {
    return true;
  }
  return false;
};

const setMatcher = (action) => {
  const regex = /_PUT$/g;
  if (regex.exec(action)) {
    return true;
  }
  return false;
};

// Checks if GET is a ?bla=   or /id/ only add / if /id/
const _addIfNotQuery = (url) => {
  const regex = /\\?$/g;
  if (regex.exec(url)) {
    return "";
  }
  return "/";
};

const _isQueryString = (url) => {
  const regex = /\\?$/g;
  if (regex.exec(url)) {
    return true;
  }
  return false;
};

// Check if action is _REQUEST _POST or _PUT
const takeMatcher = (action) => {
  if (requestMatcher(action) || submitMatcher(action) || setMatcher(action)) {
    return true;
  }
  return false;
};

const methodMatcher = (action) => {
  if (requestMatcher(action)) {
    return "GET";
  }
  if (submitMatcher(action)) {
    return "POST";
  }
  if (setMatcher(action)) {
    return "PUT";
  }
  return "ILLEGAL";
};

export const extendAction = (actionType, server) => {
  const action = {
    self: actionType,
    success: success(actionType),
    fail: fail(actionType),
  };

  action.url = server + actionToApi(action.self); // Finds the api call that is linked to the action
  action.method = methodMatcher(action.self);
  return action;
};

/* apiSaga listens to action calls that include _REQUEST _POST or _PUT. If found
 it uses actionToApi to that links the action to the actual API method on the bakend
 Then Uses the api.submit to call the bakend */
export function* apiSaga() {
  while (true) {
    // for GET this can be ID or nothing, for PUT/POST this is the data.

    const server = getServer();
    const input = yield take((action) => takeMatcher(action.type));
    const action = extendAction(input.type, server);

    let url = action.url;

    let session_add = "";

    let data = null;

    if (input.payload && action.method === "GET") {
      url = url + input.payload + session_add + _addIfNotQuery(url);
    } else {
      url = url + session_add;
      data = input.payload;
    }

    const token = "bla";

    try {
      console.log("globals sagas apiSaga");
      console.dir(url);
      const response = yield call(api.submit, url, token, action.method, data);
      yield put({ type: action.success, payload: response });
    } catch (e) {
      yield put({ type: action.fail, message: e });
    }
  }
}

function* rootSaga() {
  yield all([apiSaga(), loginSaga()]);
}

export default rootSaga;
