import { Divider } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";

interface JT {
  children: string;
}

export default function JTourSubSplit({ children }: JT) {
  return (
    <>
      <Typography>
        <h4>{children}</h4>
      </Typography>
    </>
  );
}
