import React, { useContext } from "react";

import "./JBox.css";

const boxTheme = {
  variants: {
    default: "jbox-default",
    page: "jbox-page",
    big: "jbox-big",
    small: "jbox-small",
    ionic: "jbox-ionic",
    center: "jbox-flex-center",
    row_around: "jbox-row-around",
    row_start: "jbox-row-start",
    row_between: "jbox-row-between",
    row: "jbox-row",
    row_wrap: "jbox-row_wrap",
    row_end: "jbox-row-end",
    column_start: "jbox-column-start",
    divider: "jbox-divider",
    vertical: "jbox-vertical",
    text_box: "jbox-text-box",
  },
};

interface IBoxProps {
  variant?: keyof typeof boxTheme.variants;
  bUnderline?: boolean;
  children?: any;
}

const JBox = ({
  variant = "default",
  bUnderline = false,
  children,
}: IBoxProps) => {
  return (
    <div
      className={`${boxTheme.variants[variant]} ${
        bUnderline && "jbox-underline"
      }`}
    >
      {children}
    </div>
  );
};

export default JBox;
