export const getServer = () => {
  "http://127.0.0.1:8000/";
};

export const success = (actionType: string) => {
  return `${actionType}/SUCCESS`;
};
export const fail = (actionType: string) => {
  return `${actionType}/FAIL`;
};
export const createAction = (actionType: string) => {
  return {
    self: actionType,
    success: success(actionType),
    fail: fail(actionType),
  };
};

// TODO: Refactor actions now that the nav keyword has been invented!
// This also affects sagas since we will have manager sagas and navigation sagas.

export const aT = {
  common: {
    man: {
      LOAD_PAGE_MAN: "LOAD_PAGE_MAN",
    },
    api: {},
    store: {
      ADD_TO_SESSION_STORE: "ADD_TO_SESSION_STORE",
    },
  },
};

export const dict_server = {
  using_heroku: "using_heroku",
  using_local: "using_local",
};
export type type_server = keyof typeof dict_server;
