import {
  AddCircleOutline,
  RemoveCircle,
  RemoveCircleOutline,
} from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React from "react";
import JBox from "../texting/JBox";
import { formatCurrencyDots } from "../styleUtil";

interface TP {
  unitPrice: number;
  pushUnits: (val: number) => void;
}

export default function TPrice({ unitPrice = 0, pushUnits }: TP) {
  const [num, setnum] = React.useState<number>(1);
  function add(isAdd: boolean) {
    let test = isAdd ? num + 1 : num - 1;
    setnum(test);
  }
  return (
    <div style={{ width: "100%", paddingRight: 20, paddingTop: 20 }}>
      <JBox variant="center">
        <div style={{ width: "100%", height: 50 }}>
          <div style={{ paddingRight: 20 }}>
            <JBox variant="row_between">
              <IconButton onClick={() => add(false)}>
                <RemoveCircleOutline></RemoveCircleOutline>
              </IconButton>
              <TextField
                style={{
                  width: 100,
                }}
                value={num}
                label="Fjöldi í ferð"
              />

              <IconButton onClick={() => add(true)}>
                <AddCircleOutline></AddCircleOutline>
              </IconButton>
            </JBox>
          </div>
        </div>
      </JBox>

      <div style={{ width: "100%", height: 50, paddingTop: 20 }}>
        <div style={{ marginLeft: "30%" }}>
          <h3>Verð {formatCurrencyDots(num * unitPrice)}</h3>
        </div>
      </div>
      <div style={{ marginLeft: "30%" }}>
        <Button variant="contained" onClick={() => pushUnits(num)}>
          Panta ferð
        </Button>
      </div>
    </div>
  );
}
