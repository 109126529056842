import React from "react";

import "./TripOview.css";
import { formatCurrencyNum } from "../styleUtil";
import JBox from "../texting/JBox";
import { Chip } from "@mui/material";
import { FormatUnderlined } from "@mui/icons-material";

interface IT {
  tour: string;
  unitPrice: number;
  duration: number;
  src: string;
}

export default function TripOviewImage({ tour, unitPrice, duration, src }: IT) {
  return (
    <div style={{ width: "100%", height: 250 }}>
      <JBox variant="center">
        <div className="container">
          <img
            src={src}
            alt="Mynd af húsi og bílum"
            width={300}
            height={250}
            style={{ width: "100%" }}
          ></img>
          <div className="bottom-left">
            <Chip
              label={"Verð " + formatCurrencyNum(unitPrice)}
              color="secondary"
            />
          </div>
          <div className="top-left">
            <h3 style={{ textDecoration: "underline" }}>{tour}</h3>
          </div>

          <div className="bottom-right">
            <Chip label={"dagar " + duration} color="primary" />
          </div>
        </div>
      </JBox>
    </div>
  );
}
